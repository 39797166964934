.App {
  text-align: center;
}

.App-logo {
  height: auto;
  pointer-events: none;
  width: 80%;
}

.App-link {
  color: #61dafb;
}

.App-row {
  padding-top: 10px;
}
